/* MEDIA QUERIES */
@media (min-width: 576px) {
    .container {
        max-width: 95%;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 95%;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 1300px;
    }

}

@media (min-width: 1200px) {
    .container {
        max-width: 1300px;
    }
}
/* EOF MEDIA QUERIES */


/* UTILS */

*, ::after, ::before {
    font-family: "Open Sans";
    transition: all cubic-bezier(0.39, 0.575, 0.565, 1);
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: transparent;
}

.site-container {
    margin-top: -80px; 
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
}

.hide {
    visibility: hidden !important;
}

.logo-bg {
    background-image: url(../img/apxus-logo-white.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

.logo-bg.purple {
    background-image: url(../img/apxus-logo-purple.svg);
}

.logo-small-bg {
    background-image: url(../img/apxus-logo-white-small.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

.logo-small-bg.purple {
    background-image: url(../img/apxus-logo-purple-small.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

.section-wrapper {
    padding: 60px 0;
    background-color: #303030;
}

.section-wrapper-white {
    position: relative;
    padding: 60px 0;
    background-color: white;
    z-index: -2;
}

.section-wrapper-grey-triangle {
    position: relative;
    padding-top: 180px;
    min-height: 500px;
}

.section-wrapper-grey-triangle::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 109%;
    top: 0;
    left: 0;
    -webkit-transform: skewY(-12deg);
    transform: skewY(9deg);
    background: #F2F2F2;
    -webkit-box-shadow: inset 6px 5px 5px 0px rgba(218, 218, 218, 0.75), inset 6px -5px 5px 0px rgba(218, 218, 218, 0.75);
    -moz-box-shadow: inset 6px 5px 5px 0px rgba(218, 218, 218, 0.75), inset 6px -5px 5px 0px rgba(218, 218, 218, 0.75);
    box-shadow: inset 6px 5px 5px 0px rgba(218, 218, 218, 0.75), inset 6px -5px 5px 0px rgba(218, 218, 218, 0.75);
}

.section-header {
    font-family: Raleway;
    font-size: 2.6rem;
    display: table;
    width: 100%;
}

.section-header .section-header-logo {
    width: 160px;
    height: 60px;
    display: block;
    float: left;
    margin-right: 20px;
}

.section-header .section-header-text {
    height: 60px;
    padding-top: 7px;
    float: left;
    font-weight: 300;
}

.section-body {
    padding: 30px 0;
    color: #D7D7D7;
    font-family: "Open Sans";
    font-size: 20px;
}

.font-white {
    color: #D7D7D7;
}

.font-black {
    color: #000;
}

.title-wrapper {
    font-family: Raleway;
    font-size: 27px;
    font-weight: 600;
    display: table;
}

.clear-button {
    display: table;
    border: 1px solid white;
    padding: 8px 30px;
    margin: 0 20px;
    border-radius: 30px;
    color: #fff;
    transition: all 0.8s ease 0s;
    font-weight: 600;
    font-size: 19px;
}

.clear-button:hover {
    text-decoration: none;
    background: #fff;
    color: #7146BD;
}

.purple-button {
    display: table;
    border: 1px solid #7146BD;
    padding: 8px 30px;
    margin: 0 20px;
    border-radius: 30px;
    color: #7146BD;
    transition: all 0.8s ease 0s;
    font-weight: 600;
    font-size: 19px;
}

.purple-button:hover {
    text-decoration: none;
    background: #7146BD;
    color: white;
}

.white-button {
    display: table;
    border: 1px solid #fff;
    padding: 8px 30px;
    margin: 0 20px;
    border-radius: 30px;
    color: #fff;
    transition: all 0.8s ease 0s;
    font-weight: 600;
    font-size: 19px;
    background: transparent;
    cursor: pointer;
}

.white-button:hover {
    text-decoration: none;
    background: #fff;
    color: #303030;
}

.light-text {
    font-weight: 300;
    font-size: 18px;
}

input {
    background: #fff;
    border: 0;
    padding: 0 15px;
    outline: none;
    transition: all 0.8s ease 0s;
    margin: 10px 0;
    height: 50px;
    

}

::placeholder {
    color: #D9D9D9;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #D9D9D9;
}

::-ms-input-placeholder {
    color: #D9D9D9;
}

input:focus {
    font: #5e5e5e;
    background: #fff;
}

.overlay {
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    width: 100%;
    height: calc(100% - 68px);
    bottom: 0;
    display: none;
}

.overlay.show {
    display: block;
}

#cookieModal .modal-content {
    background-color: #7146BD;
    color: white;
    width: 700px;
}

#cookieModal .modal-body {
    padding: 2rem 2rem 1rem 2rem;
}

#cookieModal .modal-header {
    border-bottom: none;
    font-family: Raleway;
}

#cookieModal .modal-footer {
    border-top: none;
    padding: 1rem 2rem 2rem 2rem;
}

#cookieModal .modal-footer button { 
    margin-right: 0;
}

#cookieModal .modal-dialog {
    max-width: 700px;
    width: 700px;
}

@media only screen and (max-width: 768px) {

    #cookieModal .modal-dialog {
        max-width: 70%;
        width: 600px;
        margin: 0 auto;
    }

    #cookieModal .modal-content {
        max-width: 100%;
        width: 100%;
    }
}

@media only screen and (max-width: 420px) {

    #cookieModal .modal-dialog {
        max-width: 88%;
        width: 88%;
    }
    
    #cookieModal .modal-content {
        max-width: 100%;
        width: 100%;
    }

}

/* EOF UTILS */

/* NAV */

.top-header-wrapper {
    min-height: 80px;
    background: url(../img/splash-bg.jpg) no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
}

nav { 
    padding: 20px;
}

nav .container {
    padding: 0;
    display: table;
}

@media only screen and (max-width: 768px) {
    nav {
        padding: 14px 20px;
    }

    nav #nav-hamburger {
        right: 0;
    }
}

@media only screen and (max-width: 499px) {
    nav {
        padding: 14px 20px;
    }

    nav #nav-hamburger {
        right: 0;
    }
}

nav.sticky { 
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    background-color: white;
    box-shadow: 6px 5px 17px -5px rgba(0, 0, 0, 0.2);
}

nav.nav-bar {
    display: table;
    width: 100%;
}


nav .container {
    position: relative;
}

nav .nav-link-logo {
    display: block;
    float: left;
    background-size: 100%;
    background-position: center;
    width: 120px;
    height: 40px;
}

#nav-hamburger {
    width: 30px;
    height: 22px;
    position: absolute;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(0deg);
    -moz-transform: translateY(-50%) rotate(0deg);
    -o-transform: translateY(-50%) rotate(0deg);
    transform: translateY(-50%) rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

#nav-hamburger span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #D7D7D7;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.sticky #nav-hamburger span{
    background: #7146BD;
}

#nav-hamburger input {
    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;
    width: 100%;
    height: 100%;
    position: absolute;
    margin: 0;
}

#nav-hamburger span:nth-child(2) {
    top: 0px;
}
  
#nav-hamburger span:nth-child(3),#nav-hamburger span:nth-child(4) {
    top: 9px;
}
  
#nav-hamburger span:nth-child(5) {
    top: 18px;
}
  
#nav-hamburger input:checked ~ span:nth-child(2),
#nav-hamburger.open span:nth-child(2) {
    top: 18px;
    width: 0%;
    left: 50%;
}

#nav-hamburger input:checked ~  span:nth-child(3),
#nav-hamburger.open span:nth-child(3) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#nav-hamburger input:checked ~  span:nth-child(4),
#nav-hamburger.open span:nth-child(4) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#nav-hamburger input:checked ~  span:nth-child(5),
#nav-hamburger.open span:nth-child(5) {
    top: 18px;
    width: 0%;
    left: 50%;
}

.navigation-slider {
    position: fixed;
    bottom: 0;
    height: calc(100% - 68px);
    width: 420px;
    background: white;
    z-index: 9;
    right: -420px;
    transition: all 0.8s ease 0s;
}

.navigation-slider.show {
    right: 0;
}

.navigation-slider ul {
    list-style: none;
    padding: 80px 35px;
}

.navigation-slider ul li {
    margin-bottom: 25px;
} 

.navigation-slider ul a {
    font-size: 26px;
    font-weight: 600;
    font-family: "Raleway";
    color: #515151;
    border-bottom: 0;
    transition: all 0.3s linear 0s;
    position: relative;
}

.navigation-slider ul a:hover, .navigation-slider ul a:active {
    text-decoration: none;
}

.navigation-slider ul a::before {
    content: "";
    position: absolute;
    border-bottom: 6px solid #733cbe;
    width: 0;
    bottom: -7px;
    padding-top: 5px;
    transition: all 0.3s linear 0s;
}

.navigation-slider ul a:hover::before {
    width: 100%;
}


@media only screen and (max-width: 420px) {

    .navigation-slider {
        width: 100%;
    }

}

/* EOF NAV */

/* SIDE COMPARISON SLIDER */

.side-comparison-slider {
    height: 420px;
    position: relative;
    cursor: ew-resize;
    margin: 60px 0 0;
}

.side-comparison-slider .old-side img, 
.side-comparison-slider .new-side img{
    max-width: none;
}

.side-comparison-slider .old-side img{
    float: left;
}

.side-comparison-slider .new-side img{
    float: right;
}

.side-comparison-slider .old-side {
    height: 100%;
    width: 50%;
    overflow: hidden;
    float: left;
    transition: all 0.8s ease 0s;
}

.side-comparison-slider .new-side {
    height: 100%;
    width: 50%;
    overflow: hidden;
    float: right;
    transition: all 0.8s ease 0s;
}

.side-comparison-slider .slide-handle {
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 5px;
    top: 0;
    bottom: 0;
    background: #FFF;
    border-radius: 2px;
    transition: all 0.8s ease 0s;
}

.side-comparison-slider-mobile {
    height: 420px;
    position: relative;
    cursor: ew-resize;
    margin: 60px 0 0;
}

.side-comparison-slider-mobile .swipe {
    float: left;
    width: 50px;
    position: relative;
    height: 250px;
}

.side-comparison-slider-mobile .content {
    float: right;
    width: calc(100% - 75px);
}

.side-comparison-slider-mobile .swipe img {
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.side-comparison-slider-mobile .tab-content>.tab-pane {
    overflow: auto;
}

.side-comparison-slider-mobile .tab-content>.tab-pane img {
	max-height: 340px;
}

.side-comparison-slider-mobile .nav-tabs {
    border-bottom: 0 !important;
    position: relative;
    display: table;
    width: 100%;
    height: 51px;
}

.side-comparison-slider-mobile .nav-tabs .nav-item {
    position: absolute;
}

.side-comparison-slider-mobile .nav-tabs .nav-item:nth-child(1) {
    left: 0;
    width: 50%;
}

.side-comparison-slider-mobile .nav-tabs .nav-item:nth-child(2) {
    right: 0;
    width: 50%;
    text-align: right;
}

.side-comparison-slider-mobile .nav-tabs .nav-item,
.side-comparison-slider-mobile .nav-tabs .nav-item a, 
.side-comparison-slider-mobile .nav-tabs .nav-item.show .nav-link, 
.side-comparison-slider-mobile .nav-tabs .nav-link.active {
    color: #D7D7D7;
    background-color: transparent;
    border-color: transparent;
    font-weight: 600;
    font-size: 19px;
}

.side-comparison-slider-mobile .nav-tabs .nav-link.active::after {
    content: "";
    display: table;
    width: 100%;
    height: 3px;
    position: absolute;
    top: 100%;
    left: 0;
    background: #733cbe;
    background: -moz-linear-gradient(left, #733cbe 1%, #4dbcb1 100%);
    background: -webkit-linear-gradient(left, #733cbe 1%,#4dbcb1 100%);
    background: linear-gradient(to right, #733cbe 1%,#4dbcb1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#733cbe', endColorstr='#4dbcb1',GradientType=1 );
    
/*     
    -webkit-border-image: 
      -webkit-gradient(linear, 0 0, 0 100%, from(black), to(rgba(0, 0, 0, 0))) 1 100%;
    -webkit-border-image: -webkit-linear-gradient(left, #733cbe 1%,#4dbcb1 100%);
    -moz-border-image: -moz-linear-gradient(left, #733cbe 1%, #4dbcb1 100%);  
    border-image: linear-gradient(to right, #733cbe 1%,#4dbcb1 100%); */
}

/* EOF SIDE COMPARISON SLIDER */

/* SPLASH SECTION */

.splash-wrapper {
    min-height: 500px;
    background: url(../img/splash-bg.jpg) no-repeat;
    background-size: cover;
    background-position: center center;
}

.splash-wrapper .splash-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-family: Raleway;
}

.splash-wrapper .splash-header {
    margin-bottom: 40px;
}

.splash-wrapper .splash-header img {
    max-height: 100px;
}

.splash-wrapper .splash-body {
    font-size: 29px;
    font-family: "Open Sans";
    font-weight: 300;
}

.splash-wrapper .splash-body a {
    font-size: 20px;
    display: table;
    border: 1px solid white;
    padding: 8px 30px;
    border-radius: 30px;
    font-weight: 500;
    margin: 23px 0 0 0;
}

/* EOF SPLASH SECTION */

/* EOF FRAMEWORK AND WORKSHOP SECTION */

.framework-section {
    min-height: 800px;
    padding: 0;
}

.framework-section .left-section {
    flex: 1 45%;
    position: relative;
}

.framework-section .right-section {
    flex: 0 0 440px;
    position: relative;
    height: 800px;
}


@media only screen and (max-width: 992px) {
    #framework > .container {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .framework-section {
        min-height: 600px;
    }

    .framework-section .left-section {
        flex: 1 100%;
    }

    .framework-section .right-section .gear-svg {
        margin-top:0;
        left: 50%;
        transform: translateX(-50%);
    }

    .framework-section .right-section {
        height: auto;
        margin: 60px auto 30px auto;
    }

}

@media only screen and (max-width: 768px) {
    .framework-section .right-section .gear-svg {
        margin-top:0;
    }

    .framework-section .right-section {
        height: auto;
    }
}

.workshop-section {
    min-height: 550px;
    padding: 0;
}

.workshop-section .left-section {
    flex: 1 70%;
    position: relative;
}

.workshop-section .right-section {
    flex: 1 30%;
}

.workshop-section .left-section-content {
    justify-content: center ;
}

.top-line {
    border-left: 6px solid #733DBE;
    position: absolute;
    right: 72.2%;
    height: 314px;
    top: 0;
}

.gear-svg {
    width: 100%;
    height: 335px;
    display: block;
    position: relative;
    margin-top: 50%;
    max-width: 425px;
}

.gear-svg-big {
    background: url(../img/gear-big.svg) no-repeat;
    height: 260px;
    display: block;
    background-position: center center;
    top: 0;
    position: absolute;
    width: 260px;
    background-size: 100%;
    background-position: top;
}

.gear-svg-small {
    background: url(../img/gear-small.svg) no-repeat;
    height: 203px;
    display: block;
    background-position: center center;
    right: 0;
    bottom: 12px;
    position: absolute;
    width: 202px;
    background-size: 100%;
    background-position: bottom;
    animation-delay: 1.0s;
}

.gear-svg.animated .gear-svg-big {
    animation: rotation 3.0s infinite linear;
}
.gear-svg.animated .gear-svg-small {
    animation: rotation 3.0s infinite linear reverse;
    animation-delay: 0s;
}

.gear-svg.animated.paused .gear-svg-big, .gear-svg.animated.paused .gear-svg-small {
    animation-play-state: paused;
}

@keyframes rotation{
	0% {
		transform: rotate(0);
	}
	100%{
		transform: rotate(359deg);
	}
}


.bottom-line {
    border-left: 6px solid #4DBBB1;
    position: absolute;
    right: 48.3%;
    top: 508px;
    height: 480px;
}

.light-bulb {
    background: url(../img/icon-lightbulb.svg) no-repeat;
    width: 100%;
    height: 550px;
    background-size: 75%;
    display: block;
    background-position: center;
    visibility: visible;
}

/* .light-bulb.animated {
    animation: blinking-lightbulb 3s infinite;
}

@keyframes blinking-lightbulb {
	0% {
        background: url(../img/icon-lightbulb-noline.svg) no-repeat;
        background-size: 75%;
	}
	97%{
        background: url(../img/icon-lightbulb-noline.svg) no-repeat;
        background-size: 75%;
    }
	98%{
        background: url(../img/icon-lightbulb.svg) no-repeat;
        background-size: 75%;
	}
	99%{
        background: url(../img/icon-lightbulb-noline.svg) no-repeat;
        background-size: 75%;
	}
	100%{
        background: url(../img/icon-lightbulb.svg) no-repeat;
        background-size: 75%;
	}
} */

@media (max-width: 1300px) {
    .top-line {
        right: 72.6%;
    }
    
    .bottom-line {
        right: 48.6%;
    }

    .splash-container {
        padding: 0 15px;
    }

    .left-section {
        padding-left: 15px;
    }

    .right-section {
        padding-right: 15px;
    }
}

/* CAREER SECTION */

.career {
    justify-content: space-between;
    margin: 13px 0;
}

.career-info {
    color: #fff;
    align-self: center;
    flex: 1 1 calc(60% - 15px);
    margin-right: 30px;
}

.career-button-wrapper {
    align-self: center;
    flex: 1 1 calc(40% - 15px);
}

.career .apxus-logo-white {
    display: block;
    width: 91px;
    height: 27px;
    float: left;
    margin: 6px 13px;
}

.career .title-wrapper {
    margin-bottom: 10px;
}

.career .title-wrapper .title {
    float: left;
}

.career .clear-button {
    float: right;
}

/* EOF CAREER SECTION */

/* CONTACT SECTION */

.section-wrapper.gradient {
    background: #733cbe;
    background: -moz-linear-gradient(left, #733cbe 1%, #4dbcb1 100%);
    background: -webkit-linear-gradient(left, #733cbe 1%,#4dbcb1 100%);
    background: linear-gradient(to right, #733cbe 1%,#4dbcb1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#733cbe', endColorstr='#4dbcb1',GradientType=1 );
}

.contact {
    justify-content: space-between;
    margin: 3px 0;
}

.contact input {
    width: 495px;
    align-self: center;
}

.contact-info {
    color: #fff;
    align-self: center;
    flex: 1 1 calc(45% - 25px);
    margin-right: 50px;
    border-right: 1px solid #919ab7;
}

.contact-email-wrapper {
    flex: 1 1 calc(55% - 25px);
}

.contact-button-wrapper {
    align-self: center;
    justify-content: space-between;
}

.contact .clear-button {
    align-self: center;
}

.mobile-section {
    display: none;
}

@media only screen and (max-width: 1300px) {
    .section-wrapper {
        padding: 60px 0;
    }

    .contact input {
        width: calc(100% - 155px);
    }

    .contact .clear-button {
        width: 135px;
        margin: 0 0 0 20px;
    }
}

@media only screen and (max-width: 768px) {
    .career-info, .career-button-wrapper {
        flex: 1 1 100%;
    }
    
    .career .clear-button {
        margin: 60px 0 0 0;
        float: left;
    }

    .contact-info {
        flex: 1 1 100%;
        margin: 0;
        border: 0;
    }

    .framework-section {
        min-height: 650px;
    }

    .contact-email-wrapper {
        flex: 1 1 100%;
        margin: 30px 0 0 0;
    }

    .framework-section .left-section, .workshop-section .left-section, .workshop-section .right-section{
        flex: 1 1 100%;
        padding: 0 15px;
    }

    .left-section-content {
        padding: 30px 0 0 0;
    }

    .framework-section .right-section {
        flex: 1 1 100%;
        padding: 0;
        /* height: 300px; */
        /* padding: 0 15px; */
    }
    
    .gear-svg {
        background-size: 44%;
        /* height: 300px; */
    }
    
    .gear-svg {
        height: 255px;
        max-width: 320px;
    }

    .gear-svg-big {
        height: 200px;
        width: 200px;
    }
    
    .gear-svg-small {
        height: 150px;
        width: 150px;
    }

    .light-bulb {
        background-size: 40%;
        height: 245px;
    }

    .section-body {
        font-size: 18px;
    }

    .workshop-section .section-body {
        display: none;
    }

    .workshop-section .section-body.mobile-section {
        display: block;
        flex: 1 1 100%;
        padding: 0 15px;
        margin-bottom: 50px;
    }

    .section-header .section-header-logo {
        width: 150px;
        height: 45px;
    }

    .section-header .section-header-text {
        width: 100%;
        padding: 0;
        font-size: 36px;
    }
}

@media only screen and (max-width: 499px) {
    .contact input {
        flex: 1 1 100%;
    }

    .contact .clear-button {
        margin: 5px 0 0 0;
    }
    
    .gear-svg {
        height: 197px;
        max-width: 260px;
    }

    .gear-svg-big {
        height: 158px;
        width: 158px;
    }
    
    .gear-svg-small {
        height: 125px;
        width: 125px;
        bottom: 0;
    }

}

/* EOF CONTACT SECTION */

/* METHODOLOGY SECTION */

.methodology-section {
    min-height: 800px;
    padding: 0;
}

.methodology-section .header-section {
    flex: 1;
    position: relative;
    padding: 120px 15px;
}

.methodology-section .body-section {
    flex: 1;
}

.product-svg-wrapper, .framework-svg-wrapper, .workshop-svg-wrapper {
    flex: 1 22%;
}

.product-svg-transparent, .framework-svg-transparent, .workshop-svg-transparent {
    position: absolute;
    transition: all 1.4s ease-out 0s;
    opacity: 0.7;
}

.product-svg-transparent.animation-end, .framework-svg-transparent.animation-end, .workshop-svg-transparent.animation-end {
    transform: scale(0.75, 0.75);
    opacity: 0;
}

.bizresult-svg-wrapper {
    margin: 90px 0;
    opacity: 0;
    transform: scale(0.5, 0.5);
    transition: all 0.2s ease-out 0s;
}

.bizresult-svg-wrapper.show {
    opacity: 1;
    transition-delay: 1.2s;
    transform: scale(1, 1);
}

.bizresult-svg {
    background: url(../img/icon-businessresult.svg) no-repeat;
}

.product-svg {
    background: url(../img/icon-product.svg) no-repeat;
}

.framework-svg {
    background: url(../img/icon-framework.svg) no-repeat;
}

.workshop-svg {
    background: url(../img/icon-workshop.svg) no-repeat;
}

.product-svg, .framework-svg, .workshop-svg {
    background-size: 100%;
    background-position: center;
    display: block;
    max-width: 100%;
    height: 150px;
    width: 150px;
    margin: 15px auto;
}

.bizresult-svg {
    background-size: 100%;
    background-position: center;
    display: block;
    max-width: 100%;
    height: 180px;
    width: 180px;
    margin: 15px auto;
}

.product-svg-text, .framework-svg-text, .workshop-svg-text, .bizresult-svg-text {
    margin: 0 auto;
    display: table;
    font-size: 28px;
    color: #7146BD;
}

.text-icon-wrapper {
    font-family: "Open Sans";
    font-weight: 600;
    margin-bottom: 50px;
    font-size: 55px;
    color: #7146BD;
}

@media only screen and (max-width: 799px) {
    .product-svg-wrapper, .framework-svg-wrapper, .workshop-svg-wrapper, .bizresult-svg-wrapper {
        flex: 1 100%;
        margin-bottom: 50px;
    }
    
    .product-svg-text, .framework-svg-text, .workshop-svg-text, .bizresult-svg-text { 
        margin: 15px auto;
    }
}

/* METHODOLOGY SECTION */

/* CAREER PAGE */

.career-header-wrapper {
    min-height: 250px;
    background: url(../img/splash-bg.jpg) no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
}

.career-header-wrapper .career-container {
    position: absolute;
    top: 45%;
    font-family: Raleway;
}

.career-header-wrapper .career-header {
    color: white;
    font-weight: 600;
    font-family: Raleway;
}

.career-body-wrapper {
    background: #F2F2F2;
    padding: 60px 0;
}

.career-body-wrapper .job-item {
    background: white;
    padding: 30px 30px;
    margin-bottom: 25px;
    color: #303030;
}

.career-body-wrapper a.job-item:hover, .career-body-wrapper a.job-item:active, .career-body-wrapper a.job-item:visited {
    text-decoration: none;
    color: #303030;
}

.career-body-wrapper a.job-item:hover {
    -webkit-box-shadow: 0 0px 3px 0px rgba(0, 0, 0, 0.45);
    -moz-box-shadow: 0 0px 3px 0px rgba(0, 0, 0, 0.45);
    box-shadow: 0 0px 3px 0px rgba(0, 0, 0, 0.45);
}


.career-body-wrapper a.job-item:last-of-type {
    margin-bottom: 0;
}

.career-body-wrapper .position-title {
    flex: 1 100%;
    font-size: 22px;
}

.career-body-wrapper .position-title h1 {
    font-size: 22px;
    margin: 0;
    padding: 0;
}

.career-body-wrapper .job-location {
    flex: 1 100%;
    margin-top: 10px;
}

.career-body-wrapper .job-location i {
    background: url(../img/icon-marker.svg) no-repeat;
    width: 20px;
    height: 20px;
    background-position: center;
    display: block;
    background-size: 100%;
    float: left;
    margin-right: 10px;
}

.career-body-wrapper .job-item-header {
    flex: 1 100%;
    margin-bottom: 20px;
}

.career-body-wrapper .job-item-body {
    flex: 1 100%;
    margin: 30px 0;
}

.career-body-wrapper .job-item-left {
    flex: 1 80%;
}

.career-body-wrapper .job-item-right {
    flex: 1 20%;
}

.career-body-wrapper .job-item-body h2 {
    font-size: 20px;
    margin: 10px 0 20px 0;
}

.career-body-wrapper .job-item-body h3 {
    font-size: 20px;
    margin: 5px 0 10px 0;
}

.career-body-wrapper .job-item-body h6 {
    font-size: 20px;
    margin: 0 0 5px 0;
    padding: 0;
}

.career-body-wrapper .job-item-body ul {
    padding: 0 20px;
}

.career-body-wrapper .job-item-body p {
    margin-bottom: 3rem;
}

/* EOF CAREER PAGE */

/* FOOTER SECTION */

footer {
    background: #fff;
    padding: 35px 0;
}

.footer-wrapper {
    padding: 0 20px;
}

footer .logo-wrapper {
    flex: 1 10%;
}

footer .nav-wrapper {
    flex: 1 80%;
}
footer .action-wrapper {
    flex: 1 47px;
}

footer .logo-bg.purple {
    display: block;
    float: left;
    background-size: 100%;
    background-position: center;
    width: 120px;
    height: 40px;
}

footer .copyright-text {
    color: #7146BD;
    font-size: 13px;
    text-align: center;
}

footer ul {
    list-style: none;
    margin-bottom: 0;
    padding: 0 6%;
}

footer ul li {
    float: left;
    padding-left: 28px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    position: relative;
    margin: 0 15px;
}

footer ul li a {
    color: #363131;
}

footer .scrolltop-wrapper {
    border: 1px solid #52ABB3;
    padding: 5px;
    display: table;
}

footer .scrolltop-wrapper:hover {
    background: #52ABB3;
    text-decoration: none;
}

footer i {
    border: solid #52ABB3;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 12px;
}

footer .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin: 9px 15px;
}

footer .caret-wrapper {
    display: block;
    height: 16px;
}

footer .green-text {
    color: #52ABB3;
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 10px;
}

footer .scrolltop-wrapper:hover i {
    border: solid #fff;
    border-width: 0 2px 2px 0;
}

footer .scrolltop-wrapper:hover .green-text {
    color: #fff;
}
    
/* footer ul li:nth-child(2)::before, footer ul li:nth-child(3)::before, footer ul li:nth-child(4)::before, footer ul li:nth-child(5)::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 2px;
    border: 3px solid #000;
    left: 0;
    top: 50%;
    border-radius: 5px;
    transform: translateY(-50%);
} */

footer ul li::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 2px;
    border: 3px solid #000;
    left: 0;
    top: 50%;
    border-radius: 5px;
    transform: translateY(-50%);
}

@media only screen and (max-width: 768px) {
    .footer-wrapper {
        padding: 0;    
        flex-wrap: wrap;
    }

    footer ul {
        padding: 0;
    }
    footer ul li {
        margin: 0 15px;
    }
    
    footer ul li a {
        font-size: 14px;
    }

    .nav-wrapper {
        margin-bottom: 60px;
    }

    footer .logo-wrapper {
        flex: 1 60%;
    }
}

/* EOF FOOTER SECTION */

